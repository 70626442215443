import createDebounce from 'redux-debounced';
import rootReducer from '../reducers/rootReducer';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

const middleware = [thunk, createDebounce()];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
	persistedReducer,
	{},
	composeEnhancers(applyMiddleware(...middleware))
);
export const persistor = persistStore(store);

export default { store, persistor };
