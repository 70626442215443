import { combineReducers } from 'redux';

import auth from './auth';
import layout from './layout';
import navbar from './navbar';
import withdrawal from './withdrawal';

const rootReducer = combineReducers({
	auth,
	layout,
	navbar,
	withdrawal
});

export default rootReducer;
